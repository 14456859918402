.Field {
    max-width: 700px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-left-width: 0;
    border-right-width: 0;
    margin-bottom: 50px;
}

.Row {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.Row:last-child {
    border-bottom: 0;
}

.ResetGameButton {
    font-size: 20px;
    padding: .3rem .5rem .5rem;
    margin: 0;
    border: 0;
    background: rgba(244,244,244,.9);
    position: fixed;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    white-space: nowrap;
    color: #7f7f7f;
    border-radius: 15px 15px 0 0;
    z-index: 2;
    backdrop-filter: blur(5px);
}

.ResetGameButton--GameOver {
    background-color: rgba(255, 200, 0, .7);
    color: #111;
}

@media screen and (min-width: 700px) {
    .Field {
        border-left-width: 1px;
        border-right-width: 1px;
    }
}