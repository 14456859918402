.Cell {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
    border-right: 1px solid #ccc;
    padding: .5rem;
    font-family: 'Fira Sans Condensed';
    position: relative;
}

.Cell:last-child {
    border-right: 0;  
}

.Cell:after {
    content: "";
    display: block;
    padding-bottom: 120%;
}

.IsSelected {
    background-color: lightgreen;
    color: darkgreen;
    font-weight: 700;
}

.CellDeselect {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(215, 0, 0, .7);
    display: block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    font-weight: 400;
}

@media (min-width: 500px) {
    .Cell {
        font-size: 1rem;
    }
}

@media (min-width: 700px) {
    .Cell {
        font-size: 1.2rem;
    }
}